<template>
  <div class="view">
    <filtros
      @changeColor="(event) => (filtersColor = event)"
      :type="'FiltroTerritorio'"
      :filtersDefaultColor="filtersColor"
    >
      <template v-slot:downloads>
        <div>
          <template v-if="mapa.totalMapaUfs">
            <div
              color="primary"
              class="relatorio mt-2"
              @click="
                gerarRelatorio(nomeRelatorio.TU.sigla, nomeRelatorio.TU.nome)
              "
            >
              <img
                src="/assets/img/excel.png"
                style="cursor: pointer; width: 25px"
              />
              {{ nomeRelatorio.TU.nome }}
            </div>
          </template>
        </div>
        <div>
          <template v-if="mapa.totalMapaUfs">
            <div
              color="primary"
              class="relatorio mt-2"
              @click="
                gerarRelatorio(nomeRelatorio.TI.sigla, nomeRelatorio.TI.nome)
              "
            >
              <img
                src="/assets/img/excel.png"
                style="cursor: pointer; width: 25px"
              />
              {{ nomeRelatorio.TI.nome }}
            </div>
          </template>
        </div>
      </template>
    </filtros>
    <LegendaMapa></LegendaMapa>
    <Mapa :municipios="mapaFixo"></Mapa>
  </div>
</template>
<script>
import filtros from "@/components/mapa/components/Filtro";

import { Mapa } from "@/components/mapa";
import { municipioAPI } from "@/modules/municipio";
import { mapGetters, mapActions } from "vuex";
import LegendaMapa from "@/components/mapa/components/LegendaMapa.vue";
import { defaultMapColour } from "@/components/mapa/constants";
import gerar from "@/modules/mapa/relatorios/gerar";
import moment from "moment";
const nomeRelatorio = {
  TU: {
    nome: "Territórios por Uf",
    sigla: "TU",
  },
  TI: {
    nome: "Territórios e Iniciativas",
    sigla: "TI",
  },
};

export default {
  name: "MapaView",
  components: {
    Mapa,
    filtros,
    LegendaMapa,
  },
  data() {
    return {
      municipios: [],
      filtersColor: defaultMapColour,
      nomeRelatorio: nomeRelatorio,
    };
  },
  computed: {
    ...mapGetters(["mapa"]),
    mapaFixo() {
      return this.mapa.mapaFixo;
    },
    filter() {
      return this.mapa.filter;
    },
  },

  methods: {
    ...mapActions([
      "setLoading",
      "setMapaInicial",
      "setMapaVariables",
      "resetarMapaFixo",
    ]),
    isMobileAndTabletCheck() {
      return mobileAndTabletCheck();
    },
    manipulateMunicipio(municipioResponse, territoriosMunicipio) {
      municipioResponse.map((item) => {});
    },
    async gerarRelatorio(tipo, nome) {
      this.setLoading(true);
      try {
        const values = await gerar(tipo, this.filter);

        this.fazerDownloadRelatorio(values.data, nome);
        this.setLoading(false);
      } catch (error) {
        this.setLoading(false);
        this.$notification.error("Erro ao gerar o relatório", { timer: 5 });
        console.log("Erro no gerarRelatorio do indicadoresGame ", error);
      }
    },
    fazerDownloadRelatorio(file, nomeArquivo) {
      var fileURL = window.URL.createObjectURL(new Blob([file]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `${nomeArquivo} - ${moment().format("DD_MM_YYYY_HH_mm")}.xlsx`
      );

      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
  async mounted() {
    this.setLoading(true);
    try {
      if (localStorage.getItem("mapFiltersColors")) {
        this.filtersColor =
          JSON.parse(localStorage.getItem("mapFiltersColors")) ||
          this.filtersColor;
      } else {
        localStorage.setItem(
          "mapFiltersColors",
          JSON.stringify(this.filtersColor)
        );
      }

      this.resetarMapaFixo();

      const municipioResponse = await municipioAPI.getInfoMunicipio();

      this.municipios = municipioResponse.data;

      this.setMapaInicial(this.municipios);
      this.setMapaVariables({
        mapaFixo: this.municipios,
      });
    } catch (error) {
      console.log("🚀 ~ mounted ~ error:", error);
      this.$notification.error(`Erro ao carregar o mapa`);
    }

    this.setLoading(false);
  },
};
</script>
<style lang="scss">
.view {
  $mapaRelacionamentoColour: v-bind("filtersColor.relacionamento");
  $mapaContratoColour: v-bind("filtersColor.contrato");
  $mapaRegionColour: v-bind("filtersColor.region");
  $mapaMunicipioColour: v-bind("filtersColor.municipio");
  $mapaIndicadorColour: v-bind("filtersColor.indicador");
  $mapaUFColour: v-bind("filtersColor.uf");
  $mapaEixoColour: v-bind("filtersColor.eixo");

  .corMunicipio {
    background: $mapaMunicipioColour;

    polygon {
      fill: $mapaMunicipioColour !important;
    }
  }

  .corEixo {
    background: $mapaEixoColour;

    polygon {
      fill: $mapaEixoColour !important;
    }
  }

  .corProjeto {
    background: $mapaMunicipioColour;

    polygon {
      fill: $mapaMunicipioColour !important;
    }
  }

  .corContrato {
    background: $mapaContratoColour;

    polygon {
      fill: $mapaContratoColour !important;
    }
  }

  .corRelacionamento {
    background: $mapaRelacionamentoColour;

    polygon {
      fill: $mapaRelacionamentoColour !important;
    }
  }

  .corUF {
    background: $mapaUFColour;

    polygon {
      fill: $mapaUFColour !important;
    }
  }

  .corIndicador {
    background: $mapaIndicadorColour;

    polygon {
      fill: $mapaIndicadorColour !important;
    }
  }

  .corUR {
    background: $mapaRegionColour;

    polygon {
      fill: $mapaRegionColour !important;
    }
  }
}
</style>
