<template>
  <v-container>
    <v-dialog v-model="exibeModal" scrollable>
      <v-card>
        <v-card-title class="modal-header elevation-10">
          <v-col>
            <slot name="title"> {{ title }} </slot>
          </v-col>
          <v-col class="text-right">
            <v-icon color="white" @click="$emit('fecharRelatorio')">
              mdi-window-close
            </v-icon>
          </v-col>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-row class="rowRelatorio">
              <v-col class="ufsFiltro" style="color: #000">
                UF:
                <strong>{{ uf }} </strong>
              </v-col>
            </v-row>
            <v-row class="rowRelatorio" v-if="filtrosRelatorio.length > 0">
              <v-col v-if="filtrosRelatorio.includes('faixa')">
                <v-text-field
                  id="confirm-password"
                  label="Faixa de Ponto Inicial"
                  name="confirm-password"
                  type="number"
                  v-model="filtro.faixaPontoInicial"
                  :rules="rules"
                  solo
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col v-if="filtrosRelatorio.includes('faixa')">
                <v-text-field
                  id="confirm-password"
                  label="Faixa de Ponto Final"
                  name="confirm-password"
                  type="number"
                  v-model="filtro.faixaPontoFinal"
                  :rules="rules"
                  solo
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col v-if="filtrosRelatorio.includes('pilar')">
                <combobox-pilar
                  :filter="filtro"
                  :rules="rules2"
                  ref="pilar"
                ></combobox-pilar>
              </v-col>
              <v-col v-if="filtrosRelatorio.includes('indicador')">
                <indicador-combobox :filter="filtro"></indicador-combobox>
              </v-col>
              <v-col v-if="filtrosRelatorio.includes('criterioSelo')">
                <combobox-cliente-oculto-grupos
                  :filter="filtro"
                ></combobox-cliente-oculto-grupos>
              </v-col>
              <v-col v-if="filtrosRelatorio.includes('municipio')">
                <municipio-combobox-uf
                  :filter="filtro"
                  :isMultiple="true"
                  :solo="true"
                  :uf="uf"
                  @changeMunicipio="(e) => (filtro.municipio = e)"
                />
              </v-col>
              <v-col v-if="filtrosRelatorio.includes('ur')">
                <combobox-ur :filter="filtro" :solo="true" :uf="uf" />
              </v-col>
              <v-col cols="2">
                <v-btn color="primary" @click="filtrar">Filtrar</v-btn>
              </v-col>
            </v-row>
            <v-row class="rowRelatorio">
              <v-col>
                <v-data-table
                  :headers="header"
                  :items="values"
                  class="elevation-1"
                  :items-per-page="5"
                  :footer-props="{ itemsPerPageText: 'Itens por página' }"
                  :search="search"
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="search"
                      label="Pesquise..."
                      class="mx-4"
                    ></v-text-field>
                  </template>
                  <template slot="no-data">
                    <div v-if="!loading">
                      {{
                        !foiFiltrado
                          ? "Todos os filtros são obrigatórios"
                          : "Esse relatório ainda não possui dados"
                      }}
                    </div>
                    <div v-else>Carregando...</div>
                  </template>

                  <template v-slot:item="{ item }">
                    <tr>
                      <td
                        class="text-xs-right pa-4"
                        v-for="(value, index) in Object.values(item)"
                        :key="index"
                      >
                        <a
                          :href="value"
                          target="_blank"
                          v-if="value && value.toString().startsWith('http')"
                        >
                          <v-icon color="primary">mdi-paperclip</v-icon>
                        </a>
                        <template v-else>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                {{ diminuirValue(transformValue(value)) }}</span
                              >
                            </template>
                            <span>{{ transformValue(value) }} </span>
                          </v-tooltip>
                        </template>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:footer.page-text="items">
                    {{ items.pageStart }} - {{ items.pageStop }} de
                    {{ items.itemsLength }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="flex-grow-1"></div>
          <v-btn color="error" outlined text @click="$emit('fecharRelatorio')"
            >Fechar</v-btn
          >
          <v-btn color="info" outlined text @click="gerarRelatorio"
            >Download</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { MunicipioComboboxUf, municipioAPI } from "@/modules/municipio";
import { clienteOcultoAPI } from "@/apis/clienteOculto";
import moment from "moment";
import ComboboxPilar from "../../../eixo/components/ComboboxPilar.vue";
import { IndicadorCombobox } from "@/modules/game";
import ComboboxClienteOcultoGrupos from "../../../clienteOculto/components/ComboboxClienteOcultoGrupos.vue";
import { ComboboxUr } from "@/modules/ur";

export default {
  components: {
    ComboboxPilar,
    IndicadorCombobox,
    ComboboxClienteOcultoGrupos,
    MunicipioComboboxUf,
    ComboboxUr,
  },
  props: {
    tipoRelatorio: { type: String, required: true },
    uf: { type: String, required: true },
    exibeModal: { type: Boolean, required: true },
    filtrosRelatorio: { type: Array, required: true },
  },
  data() {
    return {
      header: [
        { text: "UF", value: "uf" },
        {
          text: "Pilar",
          value: "tema",
        },
        {
          text: "Quantidade",
          value: "quantidade",
        },
      ],
      values: [],
      rules: [(v) => !!v || "O campo é obrigatório"],
      rules2: [(v) => !!v.length || "O campo é obrigatório"],
      search: "",
      filtro: { eixo: [], indicador: [], criterio: [], ur: [], municipio: [] },
      foiFiltrado: false,
      title: "",
      headerClienteOcultoConsolidadoSituacao: [
        { text: "UF", value: "uf" },
        {
          text: "Não Iniciado",
          value: "naoIniciado",
        },
        {
          text: "Iniciado",
          value: "iniciado",
        },
        {
          text: "Finalizado",
          value: "finalizado",
        },
      ],
      headerClienteOcultoExecucaoAnalitica: [
        { text: "UF", value: "uf" },
        {
          text: "Critério",
          value: "grupoNome",
        },
        {
          text: "Realizado",
          value: "realizado",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "%",
          value: "porcentagem",
        },
      ],
      headerSituacaoDetalhadaEnvioEvidencia: [
        { text: "Regional", value: "nomeRegiao", align: "start" },
        { text: "Parceiro", value: "municipioNome", align: "start" },
        { text: "Pilar", value: "nomeTema", align: "start" },
        { text: "Critério", value: "texto", align: "start" },
        {
          text: "Arquivos Enviados",
          value: "arquivosEnviados",
          align: "start",
        },
        {
          text: "Arquivos em Análise",
          value: "arquivosAnalise",
          align: "start",
        },
        {
          text: "Arquivos Aprovados",
          value: "arquivosAprovados",
          align: "start",
        },
        {
          text: "Arquivos Rejeitados",
          value: "arquivosRejeitados",
          align: "start",
        },
        { text: "Pontos Possíveis", value: "pontosPossiveis", align: "start" },
        { text: "Pontos Obtidos", value: "pontosObtidos", align: "start" },
      ],
      regexDateWithT:
        /^(19|20)\d\d-(0[1-9]|1[012])-([012]\d|3[01])T([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,

      regexDate:
        /^\d{4}[\-\/\s]?((((0[13578])|(1[02]))[\-\/\s]?(([0-2][0-9])|(3[01])))|(((0[469])|(11))[\-\/\s]?(([0-2][0-9])|(30)))|(02[\-\/\s]?[0-2][0-9]))$/,
      regexDateWithHour:
        /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/,
    };
  },
  computed: {
    ...mapGetters(["loading", "mapa"]),
    filter() {
      return {
        uf:
          (this.mapa.filter.uf &&
            this.mapa.filter.uf
              .filter((item) => !item.headers)
              .map((a) => `'${a.uf}'`)
              .join(",")) ||
          ufNA,
        statusId:
          (this.mapa.filter.relacionamentos &&
            this.mapa.filter.relacionamentos
              .map((item) => item.statusId)
              .join(",")) ||
          null,
        statusContratoId:
          (this.mapa.filter.contrato &&
            this.mapa.filter.contrato
              .map((item) => item.statusContratoId)
              .join(",")) ||
          null,
        eixoId:
          (this.mapa.filter.eixo.length &&
            this.mapa.filter.eixo.map((item) => item.temaId).join(",")) ||
          null,
        eixoTipo: this.mapa.filter.eixoTipo,
      };
    },
  },
  watch: {
    exibeModal: function (value) {
      if (value) {
        this.values = [];
        this[this.tipoRelatorio]();
      }
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async check() {
      const isValid = await this.$refs.form.validate();
      const isValid2 = await this.$refs.pilar.check();

      return isValid && isValid2;
    },
    async filtrar() {
      // const valid = await this.check();

      // if (!valid) return;

      this.foiFiltrado = true;

      this[this.tipoRelatorio]();
    },
    transformValue(item) {
      if (typeof item === "boolean") {
        if (item) return "Sim";
        return "Não";
      } else if (
        this.regexDate.test(item) ||
        this.regexDateWithHour.test(item) ||
        this.regexDateWithT.test(item)
      ) {
        return moment(item).format("DD/MM/YYYY");
      }

      return item;
    },
    diminuirValue(item) {
      if (item && item.length > 50) {
        return item.substring(0, 50) + "...";
      }

      return item;
    },
    gerarQuantidadeParceiros() {
      this.setLoading(true);
      var filtroParceiros = {
        uf: this.uf,
        faixaPontosInicio: this.filtro.faixaPontoInicial,
        faixaPontosFim: this.filtro.faixaPontoFinal,
        temaId:
          this.filtro.eixo &&
          this.filtro.eixo.map((item) => item.temaId).join(","),
      };
      municipioAPI
        .gerarQuantidadeParceiros(filtroParceiros)
        .then(({ data: dadosrelatorio }) => {
          this.title = "Relatório - Quantitativo de Selos por UF";
          this.values = this.manipularDadosRelatorio(dadosrelatorio);
          this.setLoading(false);
        })
        .catch((err) => {
          console.log("err getQuantidadeSelosAtribuidos: ", err);
          this.setLoading(false);
        });
    },
    gerarSituacaoDetalhadaEnvioEvidencia() {
      this.header = this.headerSituacaoDetalhadaEnvioEvidencia;
      this.title = "Relatório - Situação Envio Evidências";

      this.setLoading(true);
      var filtroParceiros = {
        codigoibge:
          (this.filtro.municipio &&
            this.filtro.municipio.length > 0 &&
            this.filtro.municipio.map((item) => item.codigoIbge).join(",")) ||
          "",
        uf: this.uf,
      };
      clienteOcultoAPI
        .gerarSituacaoDetalhadaEnvioEvidencia(filtroParceiros)
        .then(({ data: dadosrelatorio }) => {
          this.values = this.manipularDadosRelatorio(dadosrelatorio);
          this.setLoading(false);
        })
        .catch((err) => {
          console.log("err getQuantidadeSelosAtribuidos: ", err);
          this.setLoading(false);
        });
    },
    gerarExecucaoAnaliticaClienteOculto() {
      this.setLoading(true);
      var filtroParceiros = {
        uf: this.uf,
        grupoId:
          (this.filtro.criterio &&
            this.filtro.criterio.map((item) => item.grupoId).join(",")) ||
          "",
      };
      this.header = this.headerClienteOcultoExecucaoAnalitica;

      clienteOcultoAPI
        .gerarExecucaoAnaliticaClienteOculto(filtroParceiros)
        .then(({ data: dadosrelatorio }) => {
          this.title = "Relatório - Execução analítica do Cliente Oculto";

          this.values = this.manipularDadosRelatorio(dadosrelatorio);
          this.setLoading(false);
        })
        .catch((err) => {
          console.log("err getQuantidadeSelosAtribuidos: ", err);
          this.setLoading(false);
        });
    },
    gerarClienteOcultoConsolidadoSituacao() {
      this.setLoading(true);
      var filtroParceiros = {
        uf: this.uf,
      };
      this.header = this.headerClienteOcultoConsolidadoSituacao;

      clienteOcultoAPI
        .gerarClienteOcultoConsolidadoSituacao(filtroParceiros)
        .then(({ data: dadosrelatorio }) => {
          this.title = "Relatório - Cliente Oculto Consolidado por Situação";

          this.values = this.manipularDadosRelatorio(dadosrelatorio);
          this.setLoading(false);
        })
        .catch((err) => {
          console.log("err getQuantidadeSelosAtribuidos: ", err);
          this.setLoading(false);
        });
    },
    manipularDadosRelatorio(relatorio) {
      return relatorio.map((d) => {
        var newObject = {};
        this.header.forEach((item) => {
          newObject = { ...newObject, [item.value]: d[item.value] };
        });

        return newObject;
      });
    },
    async gerarRelatorio() {
      this.setLoading(true);

      // const valid = await this.check();

      // if (!valid) return;

      if (this.tipoRelatorio == "gerarClienteOcultoConsolidadoSituacao") {
        var filtroParceiros = {
          uf: this.uf,
        };
        clienteOcultoAPI
          .gerarXlsClienteOcultoConsolidadoSituacao(filtroParceiros)
          .then(({ data: dados }) => {
            this.fazerDownloadRelatorio(
              dados,
              "Cliente Oculto Consolidado por Situação"
            );
            this.setLoading(false);
          })
          .catch((erro) => {
            this.setLoading(false);

            console.log("Erro no gerarRelatorio do indicadoresGame ", erro);
            this.$notification.error("Erro ao gerar o relatório", { timer: 5 });
          });
      } else if (this.tipoRelatorio == "gerarExecucaoAnaliticaClienteOculto") {
        var filtroParceiros = {
          uf: this.uf,
          grupoId:
            (this.filtro.criterio &&
              this.filtro.criterio.map((item) => item.grupoId).join(",")) ||
            "",
        };
        clienteOcultoAPI
          .gerarXlsExecucaoAnaliticaClienteOculto(filtroParceiros)
          .then(({ data: dados }) => {
            this.fazerDownloadRelatorio(
              dados,
              "Execução analítica do Cliente Oculto"
            );

            this.setLoading(false);
          })
          .catch((erro) => {
            this.setLoading(false);

            console.log("Erro no gerarRelatorio do indicadoresGame ", erro);
            this.$notification.error("Erro ao gerar o relatório", { timer: 5 });
          });
      } else if (this.tipoRelatorio == "gerarSituacaoDetalhadaEnvioEvidencia") {
        var filtroParceiros = {
          codigoibge:
            (this.filtro.municipio &&
              this.filtro.municipio.length > 0 &&
              this.filtro.municipio.map((item) => item.codigoIbge).join(",")) ||
            "",
          uf: this.uf,
        };
        clienteOcultoAPI
          .gerarXlsSituacaoDetalhadaEnvioEvidencia(filtroParceiros)
          .then(({ data: dados }) => {
            this.fazerDownloadRelatorio(dados, "Situação Envio Evidências");

            this.setLoading(false);
          })
          .catch((erro) => {
            this.setLoading(false);

            console.log("Erro no gerarRelatorio do indicadoresGame ", erro);
            this.$notification.error("Erro ao gerar o relatório", { timer: 5 });
          });
      } else {
        var filtroParceiros = {
          uf: this.uf,
          faixaPontosInicio: this.filtro.faixaPontoInicial,
          faixaPontosFim: this.filtro.faixaPontoFinal,
          temaId:
            this.filtro.eixo &&
            this.filtro.eixo.map((item) => item.temaId).join(","),
        };
        municipioAPI
          .gerarRelatorioQuantidadeParceiros(filtroParceiros)
          .then(({ data: dados }) => {
            this.fazerDownloadRelatorio(
              dados,
              "Quantidades de salas por UF, Pilar e Faixa de Pontuação"
            );
            this.setLoading(false);
          })
          .catch((erro) => {
            this.setLoading(false);

            console.log("Erro no gerarRelatorio do indicadoresGame ", erro);
            this.$notification.error("Erro ao gerar o relatório", { timer: 5 });
          });
      }
    },
    fazerDownloadRelatorio(file, nomeArquivo) {
      var fileURL = window.URL.createObjectURL(new Blob([file]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `${nomeArquivo} - ${moment().format("DD.MM.YYYY")}.xlsx`
      );

      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
};
</script>

<style lang="scss">
.ufsFiltro.col {
  background: #fff;
  font-size: 16px;
}
.v-tabs-slider-wrapper {
  width: 0% !important;
}
.rowRelatorio {
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 20px;
  color: #fff;
  background-color: #00000026;
}
</style>

<style lang="scss">
.v-data-table__wrapper {
  max-height: 320px;
  overflow-y: auto;
}
</style>
