import baseAPI from "@/http";
const routerTerMunicipio = "api/territorio-municipio";

export const territoriosMunicipiosAPI = {
  create: (indicador) => baseAPI.post(routerTerMunicipio, indicador),
  update: (indicador) => baseAPI.put(routerTerMunicipio, indicador),
  delete: (id) => baseAPI.delete(`${routerTerMunicipio}/${id}`),
  list: () => baseAPI.get(routerTerMunicipio),
  get: (id) => baseAPI.get(`${routerTerMunicipio}/${id}`),
  getXlsx: (objeto) =>
    baseAPI.post(
      `${routerTerMunicipio}/obter-xlsx-territorios-por-municipio`,
      objeto,
      { responseType: "blob" }
    ),
};
