<template>
  <div>
    <SvgPanZoom
      v-if="!isMobileAndTabletCheck()"
      style="width: 100vw; height: calc(100vh - 100px)"
      @svgpanzoom="registerSvgPanZoom"
    >
      <SVGCreate
        :municipios="municipios"
        @exibir="exibirMunicipiosNomes"
        @disabledTooltip="(e) => (disabledTooltip = e)"
      ></SVGCreate>
    </SvgPanZoom>
    <SVGCreate
      v-else
      :municipios="municipios"
      @exibir="exibirMunicipiosNomes"
      @disabledTooltip="(e) => (disabledTooltip = e)"
    ></SVGCreate>
    <div
      v-if="isFilteringData(exibirMunicipio)"
      class="municipioTitle"
      :style="{ display: disabledTooltip ? 'none' : 'block' }"
    >
      {{ exibirMunicipio.nome }} - {{ exibirMunicipio.uf }}
      <div v-if="exibirMunicipio.nomesTerritorios.trim()">
        Território - {{ exibirMunicipio.nomesTerritorios }}
      </div>
    </div>
  </div>
</template>
<script>
import SVGCreate from "./svgCreate.vue";
import { mobileAndTabletCheck } from "../../../helpers/utils";
import SvgPanZoom from "vue-svg-pan-zoom";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Mapa",
  components: {
    SVGCreate,
    SvgPanZoom,
  },
  computed: {
    ...mapGetters(["isTerritorio"]),
  },
  data() {
    return {
      svgpanzoom: null,
      exibirMunicipio: {},
      disabledTooltip: false,
    };
  },
  props: {
    municipios: { type: Array, default: () => [] },
  },
  methods: {
    isMobileAndTabletCheck() {
      return mobileAndTabletCheck();
    },
    registerSvgPanZoom(svgpanzoom) {
      this.svgpanzoom = svgpanzoom;
      this.center();
    },
    center() {
      if (!this.svgpanzoom) return;

      this.svgpanzoom.center();
    },
    exibirMunicipiosNomes(municipio) {
      this.disabledTooltip = false;
      this.exibirMunicipio = municipio;
    },
    isFilteringData(municipio) {
      if (this.isTerritorio) {
        if (
          municipio.possuiCE ||
          municipio.possuiTE ||
          (municipio.class && municipio.class.startsWith("cor"))
        )
          return true;
      } else if (!this.isSala) {
        if (
          (municipio.class && municipio.class.startsWith("cor")) ||
          (municipio.pontos && municipio.pontos > 0) ||
          (municipio.pontosPerguntas && municipio.pontosPerguntas > 0)
        ) {
          return true;
        }
        return false;
      } else {
        if (municipio.statusId === statusRelacionamentoSeloEnum.participante) {
          return true;
        }
      }
    },
  },
};
</script>
