<template>
  <v-autocomplete
    id="uf-select"
    v-model="filter.uf"
    item-text="uf"
    item-value="uf"
    :items="ufs"
    label="UF"
    placeholder="Selecione uma  UF"
    :return-object="true"
    :disabled="disabled"
    @change="emitValue"
    multiple
    :loading="loading"
    :outlined="outlined"
    hide-details="auto"
  >
    <div
      style="display: flex; padding: 0 16px; cursor: pointer"
      slot="prepend-item"
      ripple
      @click="toggle"
      v-if="!loading"
    >
      <div style="margin-right: 32px">
        <v-icon :color="filter.uf.length > 0 ? 'indigo darken-4' : ''">{{
          icon
        }}</v-icon>
      </div>
      <div>Todos</div>
    </div>
    <v-divider slot="prepend-item" class="mt-2" />

    <template v-slot:selection="{ item, index }">
      <v-chip v-bind="item" small v-if="index < maxDisplay && !item.headers">
        <span class="textoTemas">{{ item.uf }}</span>
      </v-chip>
      <span v-if="index === maxDisplay" class="grey--text caption">
        <template
          v-if="
            filter.uf.filter((item) => !item.headers).length - maxDisplay > 0
          "
        >
          (+{{ filter.uf.filter((item) => !item.headers).length - maxDisplay }}
          ufs)
        </template>
      </span>
    </template>

    <template v-slot:item="{ item }">
      <!-- HTML that describe how select should render items when the select is open -->
      <div v-if="!item.headers" class="center">{{ item.uf }}</div>
      <div class="headers center" v-if="item.headers">{{ item.uf }}</div>
    </template>
    <template slot="no-data">
      <div v-if="loading">
        <v-col align-self="center"
          ><div class="progress" v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular></div
        ></v-col>
      </div>
      <div v-else>Nenhuma UF retornada</div>
    </template>
  </v-autocomplete>
</template>

<script>
import { municipioAPI } from "@/modules/municipio";

export default {
  name: "AppUfCombobox",
  props: {
    filter: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false },
    outlined: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      ufs: [],
      regiaoSudeste: ["SP", "RJ", "ES", "MG"],
      regiaoNorte: ["AM", "RR", "AP", "PA", "TO", "RO", "AC"],
      regiaoNordeste: ["MA", "PI", "CE", "RN", "PE", "PB", "SE", "AL", "BA"],
      regiaoCentroOeste: ["MT", "MS", "GO", "DF"],
      regiaoSul: ["PR", "RS", "SC"],
      previousSelectedStructures: [],
      maxDisplay: 2,
      loading: true,
    };
  },
  computed: {
    likesAll() {
      return this.filter.uf.length === this.ufs.length;
    },
    likesSome() {
      return this.filter.uf.length > 0 && !this.ufs;
    },
    icon() {
      if (this.likesAll) return "mdi-close-box";
      if (this.likesSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  mounted() {
    this.list();
  },
  methods: {
    emitValue() {
      let added = this.filter.uf.filter(
        (val) => !this.previousSelectedStructures.includes(val)
      )[0];

      let removed = this.previousSelectedStructures.filter(
        (val) => !this.filter.uf.includes(val)
      )[0];

      let aux = [...this.filter.uf];

      aux.forEach((item) => {
        if (added) {
          //  selecionar todas as regioes de acordo com o pai
          if (item.headers) {
            if (!this.previousSelectedStructures.find((a) => a === item)) {
              if (
                item.id === "CO" &&
                !this.previousSelectedStructures.find(
                  (a) => a.uf === "Centro-Oeste"
                )
              ) {
                this.filter.uf = this.filter.uf.concat(
                  this.ufs.filter(
                    (a) =>
                      a.id === "CO" &&
                      !a.headers &&
                      !this.previousSelectedStructures.find(
                        (b) => a.uf === b.uf
                      )
                  )
                );
              } else if (
                item.id === "ND" &&
                !this.previousSelectedStructures.find(
                  (a) => a.uf === "Nordeste"
                )
              ) {
                this.filter.uf = this.filter.uf.concat(
                  this.ufs.filter(
                    (a) =>
                      a.id === "ND" &&
                      !a.headers &&
                      !this.previousSelectedStructures.find(
                        (b) => a.uf === b.uf
                      )
                  )
                );
              } else if (
                item.id === "NT" &&
                !this.previousSelectedStructures.find((a) => a.uf === "Norte")
              ) {
                this.filter.uf = this.filter.uf.concat(
                  this.ufs.filter(
                    (a) =>
                      a.id === "NT" &&
                      !a.headers &&
                      !this.previousSelectedStructures.find(
                        (b) => a.uf === b.uf
                      )
                  )
                );
              } else if (
                item.id === "SD" &&
                !this.previousSelectedStructures.find((a) => a.uf === "Sudeste")
              ) {
                this.filter.uf = this.filter.uf.concat(
                  this.ufs.filter(
                    (a) =>
                      a.id === "SD" &&
                      !a.headers &&
                      !this.previousSelectedStructures.find(
                        (b) => a.uf === b.uf
                      )
                  )
                );
              } else if (
                item.id === "S" &&
                !this.previousSelectedStructures.find((a) => a.uf === "Sul")
              ) {
                this.filter.uf = this.filter.uf.concat(
                  this.ufs.filter(
                    (a) =>
                      a.id === "S" &&
                      !a.headers &&
                      !this.previousSelectedStructures.find(
                        (b) => a.uf === b.uf
                      )
                  )
                );
              }
            }
          }
        } else {
          // remover em cascata quando remover o pai
          if (removed.headers) {
            if (item.id === removed.id) {
              this.filter.uf = aux.filter((a) => a.id !== removed.id);
            }
          } else {
            // remover o pai da marcação quando algum filho for desmarcado
            if (item.id === removed.id && item.headers) {
              this.filter.uf = aux.filter((a) => a !== item);
            }
          }
        }
      });

      this.previousSelectedStructures = this.filter.uf;

      this.$emit(
        "changeUF",
        this.filter.uf.filter((item) => !item.headers)
      );
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.filter.uf = [];
          this.$emit(
            "changeUF",
            this.filter.uf.filter((item) => !item.headers)
          );
        } else {
          this.filter.uf = this.ufs.slice();
          this.$emit(
            "changeUF",
            this.filter.uf.filter((item) => !item.headers)
          );
        }
      });
    },
    list() {
      municipioAPI
        .list(this.$store.state.uf)
        .then((resp) => {
          let aux = resp.data
            .map((item) => item.uf)
            .filter(function (el, i) {
              return resp.data.map((item) => item.uf).indexOf(el) === i;
            });

          this.ufs = this.mapUfsHeaders(aux);
        })
        .catch(() => {
          this.$notification.error("Erro ao listar municípios");
        });
    },
    mapUfsHeaders(ufs) {
      let aux = [];
      let auxregiaoSudeste = [{ uf: "Sudeste", headers: true, id: "SD" }];
      let auxregiaoNorte = [{ uf: "Norte", headers: true, id: "NT" }];
      let auxregiaoNordeste = [{ uf: "Nordeste", headers: true, id: "ND" }];
      let auxregiaoCentroOeste = [
        { uf: "Centro-Oeste", headers: true, id: "CO" },
      ];
      let auxregiaoSul = [{ uf: "Sul", headers: true, id: "S" }];

      ufs
        .sort(function (a, b) {
          if (a < b) {
            return -1;
          }
          if (b > a) {
            return 1;
          }
          return 0;
        })
        .forEach((item) => {
          if (this.regiaoCentroOeste.includes(item)) {
            auxregiaoCentroOeste.push({ uf: item, id: "CO" });
          } else if (this.regiaoNordeste.includes(item)) {
            auxregiaoNordeste.push({ uf: item, id: "ND" });
          } else if (this.regiaoNorte.includes(item)) {
            auxregiaoNorte.push({ uf: item, id: "NT" });
          } else if (this.regiaoSudeste.includes(item)) {
            auxregiaoSudeste.push({ uf: item, id: "SD" });
          } else if (this.regiaoSul.includes(item)) {
            auxregiaoSul.push({ uf: item, id: "S" });
          }
        });
      if (auxregiaoCentroOeste.length > 1) {
        aux = aux.concat(auxregiaoCentroOeste);
      }

      if (auxregiaoNordeste.length > 1) {
        aux = aux.concat(auxregiaoNordeste);
      }

      if (auxregiaoNorte.length > 1) {
        aux = aux.concat(auxregiaoNorte);
      }
      if (auxregiaoSudeste.length > 1) {
        aux = aux.concat(auxregiaoSudeste);
      }

      if (auxregiaoSul.length > 1) {
        aux = aux.concat(auxregiaoSul);
      }
      this.loading = false;
      return aux;
    },
  },
};
</script>
<style lang="scss">
.headers {
  font-weight: bold;
  color: #fff;
  background: #1e7b97;
  border-radius: 7px;
  padding: 0.5rem;
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center {
  justify-self: center;
  text-align: center;
  width: 100%;
}
</style>
