<template>
  <svg
    style="width: 100%; height: 100%"
    _ngcontent-c21=""
    xmlns:xlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="$store.state.svg"
    @mouseout="$emit('disabledTooltip', true)"
  >
    <g class="uf" transform="scale(1 -1)" style="width: 100%; height: 100%">
      <g
        :codigo="municipio.codigoIBGE"
        :nome="municipio.nome"
        :pontos="municipio.pontos || municipio.pontosPerguntas"
        v-for="municipio in municipios"
        :key="municipio.codigoIBGE"
        vector-effect="non-scaling-stroke"
        :class="municipio.class || colourMap(municipio) || 'opacity'"
        @mouseover="$emit('exibir', municipio)"
      >
        <g>
          <g v-html="municipio.poligono" />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
import {
  statusRelacionamentoEnum as relacionamentoEnum,
  statusRelacionamentoSeloEnum,
} from "@/types";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SVGCreated",
  props: {
    municipios: { type: Array, default: () => [] },
  },
  computed: {
    ...mapGetters(["isSala", "config", "isTerritorio"]),
  },
  mounted() {},
  methods: {
    colourSelo(ponto) {
      if (!config.visualizarPontuacaoSelo) return "zeroVigente";
      else {
        if (ponto === 0 || !ponto || ponto <= 75) {
          return "zeroVigente";
        } else if (ponto > 75 && ponto < 81) {
          return "bronze";
        } else if (ponto >= 81 && ponto < 90) {
          return "prata";
        } else if (ponto >= 90) {
          return "ouro";
        }
        // } else if ((ponto) => 99) {
        //   return "diamante";
        // }
      }
    },
    colourTE(municipio) {
      let className = "";

      if (municipio.possuiCE) {
        className = "possuiCE";
      }

      if (municipio.possuiTE) {
        className = "possuiTE";
      }
      if (municipio.possuiTE && municipio.possuiCE) {
        className = "possuiCETE";
      }

      return className;
    },
    colourMap(municipio) {
      const pontos = municipio.pontos || municipio.pontosPerguntas;
      const status = municipio.statusId;
      const desabilitado = municipio.desabilitado;
      if (this.isTerritorio) {
        return this.colourTE(municipio);
      } else if (status === relacionamentoEnum.vigente && !this.isSala) {
        return this.colourCE(pontos);
      } else if (
        status === statusRelacionamentoSeloEnum.participante &&
        this.isSala &&
        !desabilitado
      ) {
        return this.colourSelo(pontos);
      }
    },
    colourCE(ponto) {
      if (ponto === 0 || !ponto) {
        return "zeroVigente";
      } else if (ponto > 0 && ponto < 2000) {
        return "vinte";
      } else if (ponto > 0 && ponto < 4000) {
        return "trinta";
      } else if (ponto > 0 && ponto < 6000) {
        return "quarenta";
      } else if (ponto > 0 && ponto < 8000) {
        return "sessenta";
      } else if (ponto > 0 && ponto < 10000) {
        return "oitenta";
      } else if (ponto > 0 && ponto < 13000) {
        return "cem";
      } else if (ponto > 0 && ponto < 20000) {
        return "cem2";
      } else if (ponto > 0 && ponto < 50000) {
        return "cem3";
      } else if (ponto > 0 && ponto < 100000) {
        return "cem4";
      }
    },
  },
};
</script>
