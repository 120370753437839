import { territoriosMunicipiosAPI } from "@/apis/territorios/territoriosMunicipios";
import { territoriosIniciativasAPI } from "@/apis/territorios/territoriosIniciativas";

const types = {
  TU: territoriosMunicipiosAPI.getXlsx,
  TI: territoriosIniciativasAPI.xlsTerritorioIniciativa,
};

const gerar = async (type, filtro) => {
  const typeChoose = types[type];

  const mappedFiltro = {
    uf:
      (filtro.uf &&
        filtro.uf.length &&
        filtro.uf
          .filter((item) => !item.headers)
          .map((a) => `${a.uf}`)
          .join(",")) ||
      ufNA,
    statusId:
      (filtro.relacionamentos &&
        filtro.relacionamentos.length &&
        filtro.relacionamentos.map((item) => item.statusId).join(",")) ||
      null,
    statusContratoId:
      (filtro.contrato &&
        filtro.contrato.length &&
        filtro.contrato.map((item) => item.statusContratoId).join(",")) ||
      null,
    eixoId:
      (filtro.eixo &&
        filtro.eixo.length &&
        filtro.eixo.map((item) => item.temaId).join(",")) ||
      null,
    eixoTipo: filtro.eixoTipo,
    territorioId:
      (filtro.territorio &&
        filtro.territorio.length &&
        filtro.territorio.map((item) => item.territorioId).join(",")) ||
      null,
    statusTerritorio:
      (filtro.terStatus &&
        filtro.terStatus.length &&
        filtro.terStatus.map((item) => item.statusId).join(",")) ||
      null,
    categoriaId:
      (filtro.terCategoria &&
        filtro.terCategoria.length &&
        filtro.terCategoria
          .map((item) => item.territorioCategoriaId)
          .join(",")) ||
      null,
  };

  return await typeChoose(mappedFiltro);
};

export default gerar;
