import baseAPI from "@/http";
const routerTerIniciativas = "api/territorio-iniciativas";

export const territoriosIniciativasAPI = {
  create: (indicador) => baseAPI.post(routerTerIniciativas, indicador),
  update: (indicador) => baseAPI.put(routerTerIniciativas, indicador),
  delete: (id) => baseAPI.delete(`${routerTerIniciativas}/${id}`),
  list: () => baseAPI.get(routerTerIniciativas),
  get: (id) => baseAPI.get(`${routerTerIniciativas}/${id}`),
  xlsTerritorioIniciativa: (filter) =>
    baseAPI.post(
      `${routerTerIniciativas}/obter-xlsx-territorios-iniciativas`,
      filter,
      { responseType: "blob" }
    ),
};
